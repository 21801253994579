import ModuleShipForm from "../../ship/form";

export default function ModulePopMachineDailyScheduleShip({
  prePopOption,
  closePrePop,
  setPreReload,
  setShipReload,
}) {
  return (
    <>
      <div className="mx-3">
        <ModuleShipForm
          moduleType="pop"
          prePopOption={prePopOption}
          closePrePop={closePrePop}
          setPreReload={setPreReload}
          setShipReload={setShipReload}
          detailid={prePopOption.data.id}
        />
      </div>
    </>
  );
}
