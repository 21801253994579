import { useState } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../redux/slice/system";
import { usePop } from "../hooks";

import { readExcelFile } from "../../service/xlsxStyle";
import { com_downloadFileSingle } from "../../service/downloadFile";

import { Button } from "primereact/button";

function useUploadXlsx({
  columns,
  action = {},
  example = null, // 匯入範例
  isShowChoose = false, // 是否顯示『已選擇』檔案
  isShowConfirmPop = true, // 是否顯示 確認上傳 pop
  sheetIndex = 0, // 要取得匯入 Excel 第幾個 sheet
  methodOptions = {}, // 匯入轉換函式參數
} = {}) {
  const dispatch = useDispatch();
  const [uploadKey, setUploadKey] = useState(new Date());
  const [uploadData, setUploadData] = useState(null);
  const { isPop, popOption, openPop, closePop, renderDialog } = usePop([
    {
      key: "confirm",
      title: () => "確認",
      isOpen: false,
    },
  ]);

  const parseFile = (e) => {
    dispatch(setProgress(true));

    setUploadKey(new Date());

    let file = e.target.files[0];
    if (!file) {
      dispatch(
        setToast({
          severity: "error",
          summary: "未選擇檔案",
          detail: "",
        })
      );
      return;
    }

    readExcelFile(file, sheetIndex, methodOptions)
      .then(({ data }) => {
        document.getElementById("uploadFile").value = "";

        let result = action.format({ file, data });
        if (result.isValid) {
          setTimeout(() => {
            dispatch(setProgress(false));

            let tempData = {
              filename: file.name,
              data: result.data,
            };
            setUploadData(tempData);

            if (isShowConfirmPop) {
              if (action.confirm)
                action
                  .confirm({ filename: tempData.filename })
                  .then((result) => {
                    let { Response, message } = result;
                    if (Response === 1) {
                      confirmUpload({ data: tempData });
                    } else {
                      openPop({
                        type: "confirm",
                        data: {
                          message: action.formatMessage(result),
                        },
                      });
                    }
                    dispatch(setProgress(false));
                  });
              else {
                openPop({
                  type: "confirm",
                  data: null,
                });
              }
            } else {
              confirmUpload({ data: tempData });
            }
          }, 500);
        } else {
          dispatch(
            setToast({
              severity: "error",
              sticky: true,
              summary: "資料錯誤",
              detail: result.message,
            })
          );
          dispatch(setProgress(false));
        }
      })
      .catch((err) => console.log(err));
  };

  const confirmUpload = async ({ data = uploadData } = {}) => {
    dispatch(setProgress(true));

    let result = await action.upload({ data });
    if (result) {
      let { Response, message } = result;

      if (Response === 1) {
        setUploadData(null);
        dispatch(
          setToast({
            severity: "success",
            summary: "匯入成功",
            detail: "",
          })
        );
        closePop({ type: "confirm" });
        action.reload();
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: "匯入失敗",
            detail: message ?? "",
          })
        );
        dispatch(setProgress(false));
      }
    } else {
      closePop({ type: "confirm" });
      dispatch(setProgress(false));
    }
  };

  // 下載匯入範例
  const downloadExample = () => {
    dispatch(setProgress(true));

    com_downloadFileSingle({
      fileName: example.name,
      url: example.file,
      finishAction: () => dispatch(setProgress(false)),
    });
  };

  const renderUpload = ({ title = "匯入" } = {}) => {
    return (
      <>
        <Button
          className="p-button-warning p-button-sm w-auto md:ml-auto ml-2 px-3"
          type="button"
          label={`${title}${uploadData && isShowChoose ? "(已選擇)" : ""}`}
          onClick={() => document.getElementById("uploadFile").click()}
        />
        <input
          id="uploadFile"
          type="file"
          name="file"
          className="hidden"
          accept=".xlsx,.xls,.xlsm"
          onChange={(e) => parseFile(e)}
        />

        {example && (
          <Button
            className="p-button-secondary p-button-sm w-auto ml-2 px-3"
            type="button"
            label="範例"
            onClick={() => downloadExample()}
          />
        )}

        {renderDialog({
          className: "w-11 md:w-10 lg:w-8 max-w-30rem",
          children: (
            <>
              {isPop.confirm?.isOpen && (
                <ModulePopConfirm
                  prePopOption={popOption}
                  closePrePop={closePop}
                  confirmUpload={confirmUpload}
                  setUploadData={setUploadData}
                  action={action}
                />
              )}
            </>
          ),
        })}
      </>
    );
  };

  return { uploadColumns: columns, parseFile, renderUpload, action };
}

function ModulePopConfirm({
  prePopOption,
  closePrePop,
  confirmUpload,
  setUploadData,
  action,
}) {
  return (
    <div className="text-center px-3 pb-3">
      <div className="inline-flex align-items-center justify-content-center w-5rem h-5rem text-6xl text-white bg-orange-500 border-circle">
        ！
      </div>

      {prePopOption.data?.message ? (
        prePopOption.data.message
      ) : (
        <h1 className="mt-4 mb-3">確定上傳?</h1>
      )}

      <div className="w-full">
        <Button
          className="p-button-secondary px-4"
          type="button"
          label="取消"
          onClick={() => {
            document.getElementById("uploadFile").value = "";
            setUploadData(null);
            action.upload({ data: null });
            closePrePop({ type: "confirm" });
          }}
        />
        <Button
          className="p-button-main ml-4 px-4"
          type="button"
          label="確定"
          onClick={() => confirmUpload()}
        />
      </div>
    </div>
  );
}

export default useUploadXlsx;
