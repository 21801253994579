import {
  createXlsxColumn,
  createXlsxCellStyle,
  createColumnWidth,
  createColumnHeight,
  createColumnMerge,
  parseSheetName,
  xlsxCellSerial,
} from "../../../../../service/excel";

export const createXlsxDailyProduceRecord = ({
  title,
  data: { workbook, headColumns, data },
}) => {
  const ws = workbook.addWorksheet(title);
  const commonStyles = { align_h: "center", wrapText: false };
  const headStyles = { fill_color: "d9e1f2", font_bold: true };

  headColumns.forEach((column, cIdx) => {
    createXlsxColumn({
      ws,
      column: `${xlsxCellSerial[cIdx]}1`,
      value: column.header,
      styles: createXlsxCellStyle({
        ...headStyles,
        font_bold: true,
        align_h: "left",
      }),
    });
  });

  console.log(data);
  data.map((item, index) => {
    headColumns.forEach((column, cIdx) => {
      createXlsxColumn({
        ws,
        column: `${xlsxCellSerial[cIdx]}${2 + index}`,
        value: ["modelKey", "produce_amount", "ship_amount"].includes(
          column.key
        )
          ? item[column.key]
          : item[`${column.key}_format`] ?? "-",
        styles: createXlsxCellStyle({
          ...commonStyles,
          align_h: ["modelKey"].includes(column.key) ? "left" : "center",
          fill_color:
            item[column.key]?.status === -1
              ? "c8e2d6"
              : item[column.key]?.status === 1
              ? "f7cfd2"
              : "ffffff",
        }),
        numFmt: column.numFmt ?? null,
      });
    });
  });

  createColumnWidth({
    ws,
    columns: headColumns.map((column, cIdx) => ({
      column: xlsxCellSerial[cIdx],
      width: cIdx === 0 ? 25 : [1, 2].includes(cIdx) ? 17 : 10,
    })),
  });

  // let columnHeight = [];
  // for (let i = 0; i < 10 + boundaryData.data.length; i++) {
  //   columnHeight.push({ column: i + 1, height: 24 });
  // }
  // createColumnHeight({
  //   ws,
  //   columns: columnHeight,
  // });
  createColumnMerge({
    ws,
    columns: [],
  });
};
