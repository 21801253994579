import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import { useHookForm } from "../../../../components/hooks";
import { MachineAPI } from "../../../../axios/api";
import moment from "moment";

import { FormCalendar } from "../../../../components/formElement";

import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export default function ModulePopMachineDailyScheduleDelete({
  prePopOption,
  closePrePop,
  optionData,
  setPreReload,
}) {
  const dispatch = useDispatch();
  // 表單欄位預設值
  const defaultValues = {
    date: null,
  };
  const { handleSubmit, setValue, trigger, yup, setSchema, errors, setting } =
    useHookForm({
      defaultValues,
    });

  useEffect(() => {
    setSchema(
      yup
        .object({
          date: yup.string().required("必填"),
        })
        .required()
    );
  }, []);

  const onSubmit = (submitData) => {
    dispatch(setProgress(true));

    let params = {
      year: Number(moment(submitData.date).format("YYYY")),
      month: Number(moment(submitData.date).format("MM")),
    };

    MachineAPI.deleteDailyProduceRecordDatas({
      data: params,
    }).then((result) => {
      let { Response, data, message } = result;

      if (Response === 1) {
        dispatch(
          setToast({
            severity: "success",
            summary: "成功刪除",
            detail: "",
          })
        );
        setPreReload((state) => ({ list: true }));
        closePrePop({ type: prePopOption.type });
      } else {
        dispatch(
          setToast({
            severity: "error",
            summary: message,
            detail: "",
          })
        );
      }
      dispatch(setProgress(false));
    });
  };

  return (
    <>
      <div className="mx-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-column align-items-start sm:flex-row sm:align-items-center field col-12 p-0">
            <label
              htmlFor="date"
              className={classNames(
                { "p-error": errors.date },
                "col-12 sm:col-3 text-left sm:text-right mb-1 sm:mb-0"
              )}
            >
              <span className="text-red-400 mr-1">*</span>
              刪除年/月
            </label>
            <div className="col-12 sm:col-9 py-0">
              <FormCalendar
                setting={setting}
                data={{
                  name: "date",
                  view: "month",
                  dateFormat: "yy/mm",
                  placeholder: "年/月",
                }}
              />
            </div>
          </div>

          <div className="text-right mt-3">
            <Button
              className="p-button-secondary p-button-outlined px-3"
              type="button"
              label="取消"
              onClick={() => closePrePop({ type: prePopOption.type })}
            />
            <Button
              className="p-button-danger ml-2 px-3"
              type="submit"
              label="刪除"
            />
          </div>
        </form>
      </div>
    </>
  );
}
